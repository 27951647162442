

import React , { NavLink } from "react-router-dom";

import $ from "jquery";
import { FaBars, FaTimes } from "react-icons/fa";
import { useEffect } from "react";
import Logo from "../assets/images/garionix.svg";
import { BiSolidPlusCircle } from "react-icons/bi";

interface SidebarProps {
  
  conversations: any[];

  
}
const Sidebar = ({ conversations  }: SidebarProps) => {

  useEffect(() => {
    $(".open-btn").on("click", function () {
      $(".sidebar").addClass("active");
    });

    $(".close-btn").on("click", function () {
      $(".sidebar").removeClass("active");
    });
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    window.location.href = "/";
  };



  return (
    <>
      <button className="container btn px-1 py-3 open-btn d-lg-none .d-xl-block d-md-non d-block">
        <FaBars />
      </button>

      <div className="sidebar shadow-sm round" id="side_nav">
        <button className="btn d-lg-none .d-xl-block d-md-non d-block close-btn px-2 py-1">
          <FaTimes color="#FFFFF" fill="#FFFFFF" />
        </button>
        <div className="mx-3 mt-3 mb-4 d-flex align-items-center justify-content-between" >
                 <div className="siteLogo" style={{ display: 'flex', alignItems: 'center' }}> 
     <img src={Logo} alt="" style={{ width: '90px', height: 'auto', marginRight: '8px' }} />

 </div>
      



          <BiSolidPlusCircle color="#FFFFF" fill="#ffffff" size={35} />
        </div>
        <div className="list-sidebar flex-grow">
          <div className="mx-3 mb-1 title-topic">Today</div>
          
          
          {conversations.length > 0 && (
        <NavLink to="" className="text-decoration-none sidebr">
        {conversations[0].message}
        </NavLink>
      )}
         
          {/*
          <NavLink to="" className="text-decoration-none sidebr">
            Proposal for Company
          </NavLink>
          <NavLink to="" className="text-decoration-none sidebr">
            Create Job Description for Vac...
          </NavLink>
          <NavLink to="" className="text-decoration-none sidebr">
            Proposal for Company
          </NavLink>
          <div className="mx-3 mt-3 mb-1 title-topic">Previous 7 Days</div>
          <NavLink to="" className="text-decoration-none sidebr">
            Create Job Description for Vac...
          </NavLink>
          <NavLink to="" className="text-decoration-none sidebr">
            Proposal for Company
          </NavLink> */}
        </div>

        <button className="univ-btn-login  py-2 mt-auto mx-3 mb-3" onClick={handleLogout}>Logout</button>
      </div>
    </>
  );
};

export default Sidebar;

