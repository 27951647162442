import React from 'react';
import { Route, Routes } from 'react-router-dom'
 
import Login from '../pages/Auth/Login'
import LandingPage from '../pages/LandingPage/LandingPage'
import GoogleCallback from '../pages/Callback/callback';
import ProtectedRoute from './ProtectedRoute';
import Chat from '../pages/Chat/Chat';
import UserConsentForm from '../pages/TermsCondition/termsCondition';
import PrivacyPolicyForm from '../pages/PrivacyPolicy/privacyPolicy';


function App() {
  return (
    <div>
        <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path = "/callback" element = {<GoogleCallback/>}/>
            <Route path="/login" element={<Login />} />
            <Route path = "/terms-and-conditions" element = {<UserConsentForm/>}/>
            <Route path = "/privacy-policy" element = {<PrivacyPolicyForm/>}/>
           
            <Route element={<ProtectedRoute />}>
            <Route path="/chat/:chatId" element={<Chat />} />
             <Route path ="/chat" element={<Chat/>} /></Route>
        </Routes>
    </div>
  );
}

export default App;
