import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import { getAuthToken } from "../utils/token.utils";


function ProtectedRoute() {
    const token = localStorage.getItem('token')
    // console.log("token")
  return token ? <Outlet /> : <Navigate to="/" />;
}

export default ProtectedRoute;
