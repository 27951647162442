import React, { useState } from 'react';

import LogInModal from './LogInModal';

import { getAuthToken } from '../utils/token.utils';
import ConsentForm from '../pages/LandingPage/consentForm/consentform';
import garionix from '../../src/assets/images/garionix.svg';

function App() {
  const [show, setShow] = useState(false);
  const [showConsent, setShowConsent] = useState(false);
  const [showConsentForm, setShowConsentForm] = useState(false);

  const handleClose = () => setShow(false);
  const handleCloseConsent = () => setShowConsent(false);
  const handleShow = () => setShow(true);

  const token = getAuthToken();

  const handleShowConsentForm = () => {
    setShowConsentForm(true);
    setShowConsent(true);
  };
  const handleConsentSubmit = () => {
    setShowConsentForm(false);
    setShow(true);
  };

  return (
    <>
      <header>
        <div className='container py-3'>
          <div className='row align-items-center'>
            <div className='col-xl-2'>
              <div
                className='siteLogo'
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <img
                  src={garionix}
                  alt=''
                  style={{ width: '150px', height: 'auto', marginRight: '8px' }}
                />
              </div>
            </div>
            <div className='col-xl-10'>
              <div className='siteMenu'>
                <ul>
                  {/* {token &&
                            <li>
                              
                              <UploadModal />  
                            </li>
                            } */}
                  {!token && (
                    <li>
                      <button
                        className='univ-btn-login'
                        onClick={handleShowConsentForm}
                      >
                        Log In
                      </button>
                      {showConsentForm && (
                        <ConsentForm
                          show={showConsent}
                          handleCloseConsent={handleCloseConsent}
                          onSubmit={handleConsentSubmit}
                        />
                      )}
                      <LogInModal
                        show={show}
                        handleClose={handleClose}
                        handleShow={handleShow}
                      />
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default App;
