import React, { useState } from 'react';
import Robo from '../../../assets/images/roborts.png';
import { Link } from 'react-router-dom';
import LogInModal from '../../../components/LogInModal';
import { getAuthToken } from '../../../utils/token.utils';

function App() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const token = getAuthToken();
  return (
    <>
      <section className='py-5 how-it-works'>
        <div className='container'>
          <div className='row align-items-center '>
            <div className='col-xl-5'>
              <div className='worksImage'>
                <img src={Robo} alt='' />
              </div>
            </div>
            <div className='col-xl-6 mx-5'>
            <div className = "how-it-work">
                <h3>
                  Features and Benefits
                </h3>
                {/* <p>
                  Hey there! I'm your friendly conversational AI designed to
                  assist you in creating AWS resources effortlessly. Whether
                  you're new to AWS or a seasoned user, I'm here to simplify the
                  process for you.
                </p> */}
                <ul>
                  <li className='py-2'>
                  Easy Chat-Based Interaction : No more wrestling with AWS CLI or management consoles. Just type what you need and let our chat interface handle the rest.{' '}
                  </li>
                  <li className='py-2'> Secure and Reliable : Built with security in mind, our platform ensures that all interactions and resource deployments comply with AWS security standards. </li>
                  
                  <li className='py-2'> Increased Productivity : Spend less time managing infrastructure and more time focusing on innovation and development.</li>
                  
                  <li className='py-2'>Simplified Resource Management : Streamline your AWS resource management with a user-friendly chat interface that reduces the learning curve.</li>
                  </ul>
                  </div>
            </div>
            
          </div>
        </div>
      </section>
    </>
  );
}

export default App;
