import React from 'react';
import { Link } from 'react-router-dom';

function App() {
  return (
    <>
      <footer>
        <div className='container py-5'>
          <div className='row'>
            <div className='col-12'>
              <div className='copyrightText text-center'>
              <div style={{ display: "flex", gap: "20px", marginLeft: "500px"}}>
                  <Link to="/terms-and-conditions" className="brand-color" style={{ 
                    textDecoration: "none",  
                    fontSize: "16px", 
                    fontWeight: "bold" 
                }}>
                    <p style={{ margin: 0 }}>
                      Terms and Conditions
                    </p>
                  </Link>

                  <Link to="/privacy-policy" className="brand-color" style={{ 
                    textDecoration: "none",  
                    fontSize: "16px", 
                    fontWeight: "bold" 
                }}>
                    <p style={{ margin: 0 }}>
                      Privacy Policy
                    </p>
                  </Link>
                  </div>
                <p className = "py-2">
                  Copyright &copy; 2024 | All Rights Reserved.{' '}
                  <span className='brand-color'>Garionix</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default App;
