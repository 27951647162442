import React from 'react'

// const Errormessage = (error : any) => {
//   return (
//     error && (
//         <div
//           className="alert alert-danger alert-dismissible fade show"
//           role="alert"
//         >
//           {error}
//         </div>
//       )
//   )
// }

// export default Errormessage
const Errormessage = (error : any) => {
  return (
    
    <div
    className="alert alert-danger alert-dismissible fade show mx-5"
    role="alert"
  >
    Something went wrong please  try again
  </div>
      
  )
}

export default Errormessage