import React from 'react';
import SupportICon from '../../../assets/images/support.png'
import AccuracyIcon from '../../../assets/images/accuracy.png'
import CreationICon from '../../../assets/images/content-creator.png'
import Robo from '../../../assets/images/roborts.png';


function App() {
  return (
    <>
      {/* <section className='py-5'>
        <div className='container'>
            <div className="row">
                <div className="col-12">
                    <div className="section-title text-center">
                        <h2>Features and Benefits</h2>
                    </div>
                </div>
                <div className="col-xl-4 mt-5">
                    <div className="feature-block text-center">
                        <img src={SupportICon} alt="" />
                        <h3>Enhanced Customer Support</h3>
                        <p>AI can be integrated into chatbots and birtual assistants, improving the efficiency and availability of customer support</p>
                    </div>
                </div>
                <div className="col-xl-4 mt-5">
                    <div className="feature-block text-center">
                        <img src={AccuracyIcon} alt="" />
                        <h3>Compliance and Accuracy</h3>
                        <p>AI can be integrated into chatbots and birtual assistants, improving the efficiency and availability of customer support</p>
                    </div>
                </div>
                <div className="col-xl-4 mt-5">
                    <div className="feature-block text-center">
                        <img src={CreationICon} alt="" />
                        <h3>Content Creation and Curation</h3>
                        <p>AI can be integrated into chatbots and birtual assistants, improving the efficiency and availability of customer support</p>
                    </div>
                </div>
            </div>
        </div>
      </section> */}
      <section className='py-5'>
       
            <div className="row mx-5">
                <div className="col-12 ">
                    <div className="section-title text-center">
                        <h2>How Garionix Works</h2>
                    </div>
                </div>
                <div className = "row ps-5"> 
                 
                        <div className="col-xl-2 mt-5 mx-4">
                    <div className="feature-block text-center fixed-card">
                     
                        <h3>Ask</h3>
                        <p>Simply tell me what AWS resource you want to create.
                        You can be as specific or as general as you'd like. </p>    </div>
                </div>
                <div className="col-xl-2 mt-5  mx-4">
                    <div className="feature-block text-center fixed-card">
                       
                        <h3> Clarify</h3>
                        <p> I might ask a few follow-up questions to ensure I
                    understand your requirements correctly. Don't worry; it's
                    all part of making sure you get exactly what you need.</p>
                    </div>
                </div>   <div className="col-xl-2 mt-5 mx-4">
                    <div className="feature-block text-center fixed-card">
                      
                        <h3>Execute</h3>
                        <p> Once we've nailed down the details, I'll handle all
                    the technicalities behind the scenes, generating the
                    necessary commands or configurations to create your AWS
                    resource</p>
                    </div>
                </div>
                <div className="col-xl-2 mt-5 mx-4">
                    <div className="feature-block text-center fixed-card">
                      
                        <h3>Review</h3>
                        <p> Before finalizing anything, I'll give you a summary
                    of the proposed resource and its configurations. You can
                    double-check everything to make sure it aligns with your
                    intentions.</p>
                    </div>
                </div>
                <div className="col-xl-2 mt-5 mx-4">
                    <div className="feature-block text-center fixed-card">
                      
                        <h3>Confirmation</h3>
                        <p> Once you're satisfied, just give me the green
                    light, and I'll proceed to create your AWS resource
                    promptly.</p>
                    </div>
                </div>
                        </div>
                   
         
              
             
            </div>
       
      </section>
    </>
  );
}

export default App;
