import React from "react";
import { BsArrowUpCircleFill } from "react-icons/bs";
import { LiaSpinnerSolid } from "react-icons/lia";
interface PromptInputBoxProps {
  messageInput: string;
  setMessageInput: React.Dispatch<React.SetStateAction<string>>;
  handleEnter: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  loading: boolean;
  handleChat: (input: string) => Promise<void>;
  isNormalChatResponse: boolean;
}

const PromptInputBox = ({
  messageInput,
  setMessageInput,
  handleEnter,
  loading,
  handleChat,
  isNormalChatResponse,
}: PromptInputBoxProps) => {
  return (
    <>
      <div className="wrapper">
        <textarea
          placeholder="Write your text and tell the AI to read it to you"
          value={messageInput}
          onChange={(e) => setMessageInput(e.target.value)}
          onKeyDown={handleEnter}
          disabled={loading}
        ></textarea>
        {loading ? (
          <button type="submit" disabled={loading}>
            <LiaSpinnerSolid />
          </button>
        ) : (
          <>
            <button
              onClick={() => {
                handleChat(messageInput);
              }}
            >
              <BsArrowUpCircleFill fill="rgb(24, 34, 86);" />
            </button>
          </>
        )}
      </div>
    </>
  );
};
export default PromptInputBox;
