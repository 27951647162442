import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

interface ConsentFormProps {
  show: boolean;
  handleCloseConsent: () => void;
  onSubmit: () => void;
}

const ConsentForm: React.FC<ConsentFormProps> = ({
  show,
  handleCloseConsent,
  onSubmit,
}) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (isChecked) {
      onSubmit();
    }
  };

  return (
    <>
      <div>
        <Modal
          show={show}
          onHide={handleCloseConsent}
          size="lg"
          centered
          className="consentModal"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <h2 className="card-title">Terms and Conditions</h2>
            <div style={{ overflowY: "auto", maxHeight: "60vh" }}>
              <p className="card-text" style={{ color: "black" }}>
                Thank you for using GARIONIX!
              </p>
              <p
                className="card-text"
                style={{
                  color: "black",
                  lineHeight: "1.5",
                  fontSize: "0.65rem",
                }}
              >
                By using our Services, you agree to these terms and conditions.
              </p>
              <p
                className="card-text"
                style={{
                  color: "black",
                  lineHeight: "1.5",
                  fontSize: "0.65rem",
                }}
              >
                <h6>Acceptance of terms</h6>
                By accessing or using our website, you agree to be bound by
                these Terms of Use ("Terms") and our Privacy Policy, which can
                be found here. If you do not agree to these Terms, please do not
                use our website. We may update these Terms periodically, so
                please review them regularly. Your continued use of the website
                signifies your acceptance of any changes.
              </p>
              <p
                className="card-text"
                style={{
                  color: "black",
                  lineHeight: "1.5",
                  fontSize: "0.65rem",
                }}
              >
                <h6>Content Usage</h6>
                The content on our website, including documents, templates, and
                AI-generated text, is for informational purposes only. Please
                note that this content may not cover all possible scenarios and
                may not be suitable for your specific needs.
              </p>
              <p
                className="card-text"
                style={{
                  color: "black",
                  lineHeight: "1.5",
                  fontSize: "0.65rem",
                }}
              >
                <h6>Intellectual Property</h6>
                All original content, features, and functionality on the website
                are the exclusive property of Garionix and its licensors. Our
                content is protected by copyright, trademark, and other laws.
                You may not reproduce, distribute, create derivative works from,
                or publicly display our content without written permission from
                us.
              </p>
              <p
                className="card-text"
                style={{
                  color: "black",
                  lineHeight: "1.5",
                  fontSize: "0.65rem",
                }}
              >
                <h6>Data Privacy</h6>
                By using our website, you consent to our accessing, processing,
                and storing your uploaded documents and input data. We do not
                share or sell your data. Your data may be used to improve our AI
                models.
              </p>
              <p
                className="card-text"
                style={{
                  color: "black",
                  lineHeight: "1.5",
                  fontSize: "0.65rem",
                }}
              >
                <h6>User Rights</h6>
                You have the right to use and display any content generated by
                Garionix for personal use. You may also modify, adapt, translate,
                and incorporate the AI-generated content into other works.
              </p>
              <p
                className="card-text"
                style={{
                  color: "black",
                  lineHeight: "1.5",
                  fontSize: "0.65rem",
                }}
              >
                <h6>User Conduct</h6>
                You agree to use the website responsibly and not for any
                unlawful purposes. Specifically, you agree not to:
                <ul>
                  <li>Submit false or misleading information</li>
                  <li>Attempt unauthorized access to the website</li>
                  <li>Collect or store personal data about other users</li>
                  <li>Harass, threaten, or violate the rights of others</li>
                  <li>Post content that infringes on the rights of others</li>
                </ul>
              </p>
              <p
                className="card-text"
                style={{
                  color: "black",
                  lineHeight: "1.5",
                  fontSize: "0.65rem",
                }}
              >
                <h6>Limitation of Liability</h6>
                Garionix and its affiliates are not liable for any damages
                arising from your use of the website. This includes direct,
                indirect, incidental, special, consequential, or exemplary
                damages.
              </p>
              <p
                className="card-text"
                style={{
                  color: "black",
                  lineHeight: "1.5",
                  fontSize: "0.65rem",
                }}
              >
                <h6>Indemnification</h6>
                You agree to indemnify and hold Garionix, its affiliates, and
                their respective officers, directors, employees, and agents
                harmless from any claims arising from your use of the website or
                violation of these Terms.
              </p>
              <p
                className="card-text"
                style={{
                  color: "black",
                  lineHeight: "1.5",
                  fontSize: "0.65rem",
                }}
              >
                <h6>Governing Law and Dispute Resolution</h6>
                These Terms are governed by the laws of Nepal. Any disputes will
                be resolved exclusively in the courts of Nepal.
              </p>
              <p
                className="card-text"
                style={{
                  color: "black",
                  lineHeight: "1.5",
                  fontSize: "0.65rem",
                }}
              >
                <h6>Entire Agreement</h6>
                These Terms, along with our Privacy Policy, constitute the
                entire agreement between you and Garionix regarding your use of
                the website.
              </p>
              <p
                className="card-text"
                style={{
                  color: "black",
                  lineHeight: "1.5",
                  fontSize: "0.65rem",
                }}
              >
                <h6>Your Content</h6>
                You are responsible for the content you provide to our services.
                Ensure it does not violate any laws or these Terms. You must
                have all necessary rights to the content you submit.
              </p>
              <p
                className="card-text"
                style={{
                  color: "black",
                  lineHeight: "1.5",
                  fontSize: "0.65rem",
                }}
              >
                <h6>Similarity of Content</h6>
                Due to the nature of AI, similar outputs may be generated for
                different users. Our assignment of rights does not extend to
                outputs provided to other users.
              </p>
              <p
                className="card-text"
                style={{
                  color: "black",
                  lineHeight: "1.5",
                  fontSize: "0.65rem",
                }}
              >
                <h6>Use of Content</h6>
                We may use your content to improve our services and comply with
                legal requirements.
              </p>
              <p
                className="card-text"
                style={{
                  color: "black",
                  lineHeight: "1.5",
                  fontSize: "0.65rem",
                }}
              >
                <h6>Accuracy</h6>
                AI-generated content may not always be accurate. Do not rely on
                it as your sole source of information. Always review and verify
                the accuracy of the content before use.
              </p>
              <p
                className="card-text"
                style={{
                  color: "black",
                  lineHeight: "1.5",
                  fontSize: "0.65rem",
                }}
              >
                <h6>Discontinuation of Services</h6>
                We may discontinue our services with advance notice. If this
                occurs, you will receive a refund for any prepaid, unused
                services.
              </p>
              <p
                className="card-text"
                style={{
                  color: "black",
                  lineHeight: "1.5",
                  fontSize: "0.65rem",
                }}
              >
                <h6>Disclaimer of Warranties</h6>
                Our services are provided "as is." We make no warranties about
                the accuracy, reliability, or availability of our services.
              </p>
              <p
                className="card-text"
                style={{
                  color: "black",
                  lineHeight: "1.5",
                  fontSize: "0.65rem",
                }}
              >
                <h6>Use of Output</h6>
                Use of AI-generated output is at your own risk. Do not rely on
                it as the sole source of truth or professional advice.
              </p>
            </div>
            <form id="consentForm" onSubmit={handleSubmit}>
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="agreeCheckbox"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                />
                <label className="form-check-label" htmlFor="agreeCheckbox">
                  I agree to the above terms and conditions
                </label>
              </div>
              <button
                type="submit"
                className="univ-btn mt-3"
                disabled={!isChecked}
              >
                Submit
              </button>
            </form>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default ConsentForm;
