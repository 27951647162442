import axios from "axios";
import { API_URL } from "./api";
import { getAuthToken } from "./token.utils";

import {  refreshAccessToken , checkTokenExpiry} from "./refreshtoken.utils"


//  an axios instance with a base URL and default headers
const api = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

let isRefreshing = false;
let failedRequestsQueue: ((newToken: string) => void)[] = [];

// timer to check token expiry
const tokenExpiryCheckInterval = 10000; // 1 minute
setInterval(checkTokenExpiry, tokenExpiryCheckInterval);

api.interceptors.request.use(
  async (config) => {
    const token = getAuthToken();
    config.headers.Authorization = `Bearer ${token}`;
    await checkTokenExpiry();
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  async (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      if (!isRefreshing) {
        isRefreshing = true;

        try {
          const newToken = await refreshAccessToken();
          isRefreshing = false;

          localStorage.setItem("token", newToken);

          originalRequest.headers.Authorization = `Bearer ${newToken}`;
          return api(originalRequest);
        } catch (refreshError) {
          isRefreshing = false;
          localStorage.clear();
          window.location.href = "/";
          throw refreshError;
        }
      } else {
        return new Promise((resolve) => {
          failedRequestsQueue.push((newToken :string) => {
            originalRequest.headers.Authorization = `Bearer ${newToken}`;
            resolve(api(originalRequest));
          });
        });
      }
    }
    return Promise.reject(error);
  }
);

export default api;


