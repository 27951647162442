import api from '../../../utils/axiosInstance';
import { Conversation } from './interface';
import { Dispatch, SetStateAction } from 'react';

export const handleResourceRelation = async (
  input: any,
  setSingleStackOptionalProperties: Dispatch<SetStateAction<string[] | null>>,
  setGotSingleStackkrequiiredRelation: Dispatch<SetStateAction<boolean>>,
  setSingleStackRequiredProperties: Dispatch<SetStateAction<string[]>>,
  setConversations: Dispatch<SetStateAction<Conversation[]>>,
  setLoading: Dispatch<SetStateAction<boolean>>,
  setMessageInput: Dispatch<SetStateAction<string>>,
  conversations: Conversation[],
  setSingleStackdataKeys: Dispatch<SetStateAction<string[] | undefined>>,
  setError: React.Dispatch<React.SetStateAction<string>>
) => {
  try {
    const res = await api.post(`api/multi-resource/resource-relation`, {
      resource_relation: 'single_stack',
    });

    const dataKeys = Object.keys(res.data);
    setSingleStackdataKeys(dataKeys);

    let singleStackRequiredProperties: string[] | null = null;
    let singleStackOptionalProperties: string[] | null = null;

    dataKeys.forEach((key) => {
      const value = res.data[key];
      if (value && Array.isArray(value.required_properties)) {
        singleStackRequiredProperties = value.required_properties;
      }
      if (value && Array.isArray(value.optional_properties)) {
        singleStackOptionalProperties = value.optional_properties;
      }

      setSingleStackOptionalProperties(singleStackOptionalProperties);
    });

    if (singleStackRequiredProperties) {
      setGotSingleStackkrequiiredRelation(true);
      setSingleStackRequiredProperties(singleStackRequiredProperties);
      const newConversation: Conversation = {
        message: input,
        sender: 'You',
        reply: `Please provide ${singleStackRequiredProperties[0]} for ${dataKeys}`,
        replier: 'Garionix',
      };

      setConversations([...conversations, newConversation]);
      setLoading(false);
      setMessageInput('');
    } else {
      console.log('singleStackRequiredProperties is null');
    }
  } catch (error) {
    console.error(error);
    setError('something  went wrong Please try again');
  }
};

export const handlesingleSkipstackcancelbutton = (
  newValues: any,
  input: any,
  conversations: Conversation[],
  setConversations: Dispatch<SetStateAction<Conversation[]>>,
  singlestackoptionalpropertieskeyvalue: {
    [key: string]: any;
  },
  singleStackRequiredpropertieskey: string[],
  singleStackOptionalProperties: string[] | null,
  singleStackdatakey: string[] | undefined,
  setIsNormalChatResponse: Dispatch<SetStateAction<boolean>>,
  setResourceCreationstatus: Dispatch<SetStateAction<boolean>>,
  setIsSingleStackResourceCreation: Dispatch<SetStateAction<boolean>>,
  setGotSingleStackkrequiiredRelation: Dispatch<SetStateAction<boolean>>
) => {
  console.log('insideskip', newValues);
  console.log('input:', input);
  const propertiesMessage = (
    <div>
      These are the values you have provided to create a resource.
      {Object.entries(singleStackRequiredpropertieskey).map(
        ([key, value], index) => (
          <div className='row' key={index}>
            <div className='col-6'>
              {' '}
              <h6 className='mt-3'>{key}:</h6>
            </div>
            <div className='col-6 mt-3'>{value}</div>
          </div>
        )
      )}
      {Object.entries(singlestackoptionalpropertieskeyvalue).map(
        ([key, value], index) => (
          <div className='row' key={index}>
            <div className='col-6'>
              {' '}
              <h6 className='mt-3'>{key}:</h6>
            </div>
            <div className='col-6 mt-3'>{value}</div>
          </div>
        )
      )}
      {Object.entries(newValues).map(([key, value], index) => (
        <div className='row' key={index}>
          <div className='col-6'>
            <h6 className='mt-3'>{key}:</h6>
          </div>
          <div className='col-6 mt-3'>{String(value)}</div>
        </div>
      ))}
    </div>
  );

  const newConversation: Conversation = {
    sender: '',
    message: '',
    reply: propertiesMessage,
    replier: 'Garionix',
  };
  const cancelmessage: Conversation = {
    sender: '',
    message: '',
    reply: `Your Process  has been terminated`,
    replier: 'Garionix',
  };
  const optionalprops = (
    <div>
      <div>
        <div className='mb-2'>
          These are the optional properties for{' '}
          <strong>{singleStackdatakey}</strong>
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          {singleStackOptionalProperties &&
            singleStackOptionalProperties.length > 0 &&
            singleStackOptionalProperties
              .slice(0, Math.ceil(singleStackOptionalProperties.length / 2))
              .map((prop, index) => (
                <div key={index} className='form-check'>
                  <label
                    htmlFor={`checkbox-${prop}-${index}`}
                    className='form-check-label'
                  >
                    {prop}
                  </label>
                </div>
              ))}
        </div>

        <div className='col'>
          {singleStackOptionalProperties &&
            singleStackOptionalProperties.length > 0 &&
            singleStackOptionalProperties
              .slice(Math.ceil(singleStackOptionalProperties.length / 2))
              .map((prop, index) => (
                <div key={index} className='form-check'>
                  <label
                    htmlFor={`checkbox-${prop}-${index}`}
                    className='form-check-label'
                  >
                    {prop}
                  </label>
                </div>
              ))}
        </div>
      </div>
    </div>
  );

  const newConversationp: Conversation = {
    sender: 'you',
    message: input,
    reply: optionalprops,
    replier: 'Garionix',
  };

  setConversations([
    ...conversations,
    newConversationp,
    newConversation,
    cancelmessage,
  ]);
  setIsNormalChatResponse(true);
  setResourceCreationstatus(false);
  setIsSingleStackResourceCreation(false);
  setGotSingleStackkrequiiredRelation(false);
};

export const handlesinglestackskipbutton = (
  input: any,
  singleStackRequiredProperties: string[],
  singleStackRequiredpropertieskey: string[],
  conversations: Conversation[],
  setConversations: Dispatch<SetStateAction<Conversation[]>>,
  singlestackoptionalpropertieskeyvalue: {
    [key: string]: any;
  },

  singleStackOptionalProperties: string[] | null,
  singleStackdatakey: string[] | undefined,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setStackStackGetStackName: React.Dispatch<React.SetStateAction<boolean>>,
  setGotSingleStackkrequiiredRelation: React.Dispatch<
    React.SetStateAction<boolean>
  >,
  setError: React.Dispatch<React.SetStateAction<string>>,
  setIsNormalChatResponse: Dispatch<SetStateAction<boolean>>,
  setResourceCreationstatus: Dispatch<SetStateAction<boolean>>,
  setIsSingleStackResourceCreation: Dispatch<SetStateAction<boolean>>,
  setGotSingleStackkoptionalRelation: Dispatch<SetStateAction<boolean>>
) => {
  const newKey = singleStackRequiredProperties[0];

  // Create a new object with the skipped input value and spread the previous state
  const newValue = {
    ...singleStackRequiredpropertieskey,
    [newKey]: input,
  };

  const newValues = {
    [newKey]: input,
  };

  console.log(newValue);
  console.log('skipped called', input);
  console.log('singlekey', singleStackRequiredpropertieskey);

  const propertiesMessage = (
    <div>
      These are the values you have provided to create a resource. Are you sure
      you want to continue with these values?
      {Object.entries(newValue).map(([key, value], index) => (
        <div className='row' key={index}>
          <div className='col-6'>
            <h6 className='mt-3'>{key}:</h6>
          </div>
          <div className='col-6 mt-3'>{String(value)}</div>
        </div>
      ))}
      <div className='text-end'>
        <button
          className='univ-btn me-2'
          onClick={() =>
            handlesingleSkipstackcancelbutton(
              newValues,
              input,
              conversations,
              setConversations,
              singlestackoptionalpropertieskeyvalue,
              singleStackRequiredpropertieskey,
              singleStackOptionalProperties,
              singleStackdatakey,
              setIsNormalChatResponse,
              setResourceCreationstatus,
              setIsSingleStackResourceCreation,
              setGotSingleStackkrequiiredRelation
            )
          }
        >
          Cancel
        </button>
        <button
          className='univ-btn'
          onClick={() =>
            handleSingleStackSkipConfirmbutton(
              singlestackoptionalpropertieskeyvalue,
              newValue,
              singleStackOptionalProperties,
              singleStackdatakey,
              setConversations,

              conversations,
              setLoading,
              input,
              setStackStackGetStackName,
              setGotSingleStackkrequiiredRelation,
              setError,
              setIsNormalChatResponse,
              setGotSingleStackkoptionalRelation
            )
          }
        >
          Confirm
        </button>
      </div>
    </div>
  );

  // Create a new conversation with the properties message
  const newConversation: Conversation = {
    sender: '',
    message: '',
    reply: propertiesMessage,
    replier: 'Garionix',
  };

  // Check for optional properties

  const optionalprops = (
    <div>
      <div>
        <div className='mb-2'>
          These are the optional properties for{' '}
          <strong>{singleStackdatakey}</strong>
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          {singleStackOptionalProperties &&
            singleStackOptionalProperties.length > 0 &&
            singleStackOptionalProperties
              .slice(0, Math.ceil(singleStackOptionalProperties.length / 2))
              .map((prop, index) => (
                <div key={index} className='form-check'>
                  <label
                    htmlFor={`checkbox-${prop}-${index}`}
                    className='form-check-label'
                  >
                    {prop}
                  </label>
                </div>
              ))}
        </div>

        <div className='col'>
          {singleStackOptionalProperties &&
            singleStackOptionalProperties.length > 0 &&
            singleStackOptionalProperties
              .slice(Math.ceil(singleStackOptionalProperties.length / 2))
              .map((prop, index) => (
                <div key={index} className='form-check'>
                  <label
                    htmlFor={`checkbox-${prop}-${index}`}
                    className='form-check-label'
                  >
                    {prop}
                  </label>
                </div>
              ))}
        </div>
      </div>
    </div>
  );

  // Create a new conversation for optional properties
  const newConversationp: Conversation = {
    sender: 'you',
    message: input,
    reply: optionalprops,
    replier: 'Garionix',
  };

  // Set the second conversation
  setConversations([...conversations, newConversationp, newConversation]);
};

export const handleSingleStackSkipConfirmbutton = async (
  singlestackoptionalpropertieskeyvalue: {
    [key: string]: any;
  },
  newValue: any,
  singleStackOptionalProperties: string[] | null,
  singleStackdatakey: string[] | undefined,
  setConversations: Dispatch<SetStateAction<Conversation[]>>,
  conversations: Conversation[],
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  input: any,
  setStackStackGetStackName: React.Dispatch<React.SetStateAction<boolean>>,
  setGotSingleStackkrequiiredRelation: React.Dispatch<
    React.SetStateAction<boolean>
  >,
  setError: React.Dispatch<React.SetStateAction<string>>,
  setIsNormalChatResponse: Dispatch<SetStateAction<boolean>>,
  setGotSingleStackkoptionalRelation: React.Dispatch<
    React.SetStateAction<boolean>
  >
) => {
  console.log(newValue);
  console.log(conversations);
  setLoading(true);

  console.log(singleStackdatakey);
  const params = {
    user_resource_properties_dict: {
      [singleStackdatakey?.join('_') || 'default']: {
        required_properties: newValue,
        optional_properties: {},
      },
    },
  };

  try {
    const response = await api.post('/api/auto-cf/generate-template', params);
    const optionalprops = (
      <div>
        <div>
          <div className='mb-2'>
            These are the optional properties for{' '}
            <strong>{singleStackdatakey}</strong>
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            {singleStackOptionalProperties &&
              singleStackOptionalProperties.length > 0 &&
              singleStackOptionalProperties
                .slice(0, Math.ceil(singleStackOptionalProperties.length / 2))
                .map((prop, index) => (
                  <div key={index} className='form-check'>
                    <label
                      htmlFor={`checkbox-${prop}-${index}`}
                      className='form-check-label'
                    >
                      {prop}
                    </label>
                  </div>
                ))}
          </div>

          <div className='col'>
            {singleStackOptionalProperties &&
              singleStackOptionalProperties.length > 0 &&
              singleStackOptionalProperties
                .slice(Math.ceil(singleStackOptionalProperties.length / 2))
                .map((prop, index) => (
                  <div key={index} className='form-check'>
                    <label
                      htmlFor={`checkbox-${prop}-${index}`}
                      className='form-check-label'
                    >
                      {prop}
                    </label>
                  </div>
                ))}
          </div>
        </div>
      </div>
    );

    const newConversationp: Conversation = {
      sender: 'you',
      message: input,
      reply: optionalprops,
      replier: 'Garionix',
    };
    const propertiesMessage = (
      <div>
        These are the values you have provided to create a resource.
        {Object.entries(newValue).map(([key, value], index) => (
          <div className='row' key={index}>
            <div className='col-6'>
              <h6 className='mt-3'>{key}:</h6>
            </div>
            <div className='col-6 mt-3'>{(value as string).toString()}</div>
          </div>
        ))}
        {Object.entries(singlestackoptionalpropertieskeyvalue).map(
          ([key, value], index) => (
            <div className='row' key={index}>
              <div className='col-6'>
                {' '}
                <h6 className='mt-3'>{key}:</h6>
              </div>
              <div className='col-6 mt-3'>{value}</div>
            </div>
          )
        )}
      </div>
    );

    const newConversation: Conversation = {
      sender: '',
      message: '',
      reply: propertiesMessage,
      replier: 'Garionix',
    };

    console.log(response.data[1].response);

    const replyContent =
      typeof response.data[1].response === 'object'
        ? response.data[1].response.error || response.data[1].response
        : response.data[1].response;

    const newresponseConversationGenerated: Conversation = {
      message: '',
      sender: '',
      reply: replyContent,
      replier: 'Garionix',
    };

    const conversationsToAdd = [
      newConversationp,
      newConversation,
      newresponseConversationGenerated,
    ];

    if (!response.data[1].response.error) {
      conversationsToAdd.push({
        sender: '',
        message: '',
        reply: `Please Provide Stack Name`,
        replier: 'Garionix',
      });
    }

    setConversations([...conversations, ...conversationsToAdd]);
    setLoading(false);
    if (response.data[1].response.error) {
      setLoading(false);
      setIsNormalChatResponse(true);
      setStackStackGetStackName(false);
      setGotSingleStackkrequiiredRelation(false);
      setGotSingleStackkoptionalRelation(false);
    } else {
      setLoading(false);
      setStackStackGetStackName(true);
      setGotSingleStackkrequiiredRelation(false);
      setGotSingleStackkoptionalRelation(false);
    }
  } catch {
    setLoading(false);
    setError('Something went wrong. Please try again.');
  }

  // console.log(confirm);
};

export const handleSingleStackConfirmbutton = async (
  singlestackoptionalpropertieskeyvalue: {
    [key: string]: any;
  },
  newValue: any,
  singleStackOptionalProperties: string[] | null,
  singleStackdatakey: string[] | undefined,
  selectedOptionalProperties: string[],
  input: any,
  setConversations: Dispatch<SetStateAction<Conversation[]>>,
  conversations: Conversation[],
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setStackStackGetStackName: React.Dispatch<React.SetStateAction<boolean>>,
  setGotSingleStackkrequiiredRelation: React.Dispatch<
    React.SetStateAction<boolean>
  >,
  setGotSingleStackkoptionalRelation: React.Dispatch<
    React.SetStateAction<boolean>
  >,
  setError: React.Dispatch<React.SetStateAction<string>>,
  setIsNormalChatResponse: Dispatch<SetStateAction<boolean>>
) => {
  selectedOptionalProperties.forEach((property) => {
    singlestackoptionalpropertieskeyvalue[property] = input;
  });

  console.log(newValue);
  setLoading(true);
  console.log(input);

  console.log(singleStackdatakey);
  console.log(singlestackoptionalpropertieskeyvalue);
  const params = {
    user_resource_properties_dict: {
      [singleStackdatakey?.join('_') || 'default']: {
        required_properties: newValue,
        optional_properties: singlestackoptionalpropertieskeyvalue,
      },
    },
  };

  try {
    const response = await api.post('/api/auto-cf/generate-template', params);
    console.log(response);
    const propertiesMessage = (
      <div>
        These are the values you have provided to create a resource.
        {Object.entries(newValue).map(([key, value], index) => (
          <div className='row' key={index}>
            <div className='col-6'>
              <h6 className='mt-3'>{key}:</h6>
            </div>
            <div className='col-6 mt-3'>{(value as string).toString()}</div>
          </div>
        ))}
        {Object.entries(singlestackoptionalpropertieskeyvalue).map(
          ([key, value], index) => (
            <div className='row' key={index}>
              <div className='col-6'>
                {' '}
                <h6 className='mt-3'>{key}:</h6>
              </div>
              <div className='col-6 mt-3'>{value}</div>
            </div>
          )
        )}
      </div>
    );
    console.log(response.data[1].response);

    const replyContent =
      typeof response.data[1].response === 'object'
        ? response.data[1].response.error || response.data[1].response
        : response.data[1].response;

    const newresponseConversationGenerated: Conversation = {
      message: '',
      sender: '',
      reply: replyContent,
      replier: 'Garionix',
    };

    const newConversations: Conversation[] = [
      {
        sender: 'you',
        message: input,
        reply: propertiesMessage,
        replier: 'Garionix',
      },
      newresponseConversationGenerated,
    ];

    if (!response.data[1].response.error) {
      newConversations.push({
        message: '',
        sender: '',
        reply: `Please provide stack Name`,
        replier: 'Garionix',
      });
    }

    setConversations([...conversations, ...newConversations]);

    if (response.data[1].response.error) {
      setLoading(false);
      setIsNormalChatResponse(true);
      setStackStackGetStackName(false);
      setGotSingleStackkrequiiredRelation(false);
      setGotSingleStackkoptionalRelation(false);
    } else {
      setLoading(false);
      setStackStackGetStackName(true);
      setGotSingleStackkrequiiredRelation(false);
      setGotSingleStackkoptionalRelation(false);
    }
  } catch {
    console.log('error');
    setLoading(false);
    setError('something  went wrong Please try again');
  }
};
