const getAuthToken = () => {
  const token = localStorage.getItem(`token`)
  return token;
};

const getRefreshToken = () =>{
  const refreshToken =  localStorage.getItem(`refreshtoken`)
  return refreshToken
}

export { getAuthToken,  getRefreshToken};







