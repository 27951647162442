import React from 'react';
import Router from './routes/router'
import { Amplify} from "aws-amplify";
import awsExports from "./configs/aws";

function App() {
  Amplify.configure(awsExports);
  return (
    <>
     
      <Router />
     
    </>
  );
}

export default App;
