import React from 'react';
import { useEffect, useState } from 'react';
import axios from 'axios';

export const SystemChatIcon = () => {
  return (
    <div className='d-flex align-items-center mb-1 mt-3'>
      <div className='sidebar-head'>
        <div className='sidebar-img'>
          <div className='rounded-circle profile-img profile-sidebar'>G</div>
        </div>
      </div>
      <h6 className='mx-3'>Garionix</h6>
    </div>
  );
};

export const UserChatIcon = () => {
  const token = localStorage.getItem('token');
  const [initialletter, setInitialletter] = useState<string>('');
  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await axios.get(
          `https://${process.env.REACT_APP_OAUTH_DOMAIN}/oauth2/userInfo`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // console.log(response.data.given_name[0]);
        setInitialletter(response.data.given_name[0]); // This should contain the user information including email
        return response.data;
      } catch (error) {
        console.error('Error fetching user info:', error);
        throw error;
      }
    };

    // Call the function to fetch user info
    fetchUserInfo();
  }, []); // Empty dependency array ensures this effect runs only once
  return (
    <div className='d-flex align-items-center mb-1 '>
      <div className='sidebar-head'>
        <div className='sidebar-img'>
          <div className='rounded-circle profile-img profile-sidebar'>
            {initialletter}
          </div>
        </div>
      </div>
      <h6 className='mx-3'>You</h6>
    </div>
  );
};
