import React from 'react';

function App() {
  return (
    <>
      <section className='authWrap'>
        <div className='container'>
          <div className='row justify-content-center align-items-center'>
            <div className='col-xl-5'>
              <div className='display-card'>
                <h1>Welcome to Garionix</h1>
                <p>
                  Tired of document drudgery? We make documents and
                  presentations so that you don't have to!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default App;
