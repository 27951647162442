import React from 'react';
import Banner from './partials/Banner';
import Features from './partials/Features';
import HowWorks from './partials/HowWorks';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

function App() {
  return (
    <>
      <Header />
      <Banner />
      <Features />
      <HowWorks />
      <Footer />
    </>
  );
}

export default App;
