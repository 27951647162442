
import React, {Modal } from 'react-bootstrap';
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import {  Auth } from "aws-amplify";

interface LogInModalProps {
    show: boolean;
    handleClose: () => void;
    handleShow : ()=>void;
}

const LogInModal = ({ show, handleClose , handleShow}: LogInModalProps) => {
  
  return (
    <div>
    {/* login modal */}
     
    <Modal 
      show={show} 
      onHide={handleClose}
      centered
      className='loginModal'
      >
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body>
          <div className="authLogin">
              <h2>Login</h2>
              <p>Hey, Enter your details to get sign in to your account.</p>

              <button className='googleLogin' onClick={() => Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Google,})} > <img src="https://static.vecteezy.com/system/resources/previews/022/613/027/non_2x/google-icon-logo-symbol-free-png.png" alt="" /> Continue with Google</button>
              <div className="text-center py-2">
                  OR
              </div>
              <div className="form-group mb-4">
                  <label>Login with Email</label>
                  <input type='email' className='univ-input' placeholder='Enter Email Address' />
              </div>
             

              <div className="form-group text-center">
                  <button className='univ-btn w-100 py-2'>Login</button>
              </div>
          </div>
      </Modal.Body>
      
    </Modal></div>
  )
}

export default LogInModal