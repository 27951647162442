import { jwtDecode } from 'jwt-decode';
import api from "./axiosInstance";
import { getAuthToken } from "./token.utils";
import { getCurrentTimestampInSeconds } from './dateTimeUtils';
import { calculateTimeDifferenceInSeconds } from './dateTimeUtils';

let isRefreshing = false;
let failedRequestsQueue: ((newToken: string) => void)[] = [];

//  to refresh the access token
export const refreshAccessToken = async () => {
  try {
    const response = await api.post("api/token/generate-token/", {
      refresh_token: localStorage.getItem("refreshtoken"),
    });
    const newToken = response.data[0].access_token; 
    // console.log(newToken);

    return newToken;
  } catch (error) {
    localStorage.clear();
    window.location.href = "/";
    throw error;
  }
};

//  to check token expiration and refresh if needed
export const checkTokenExpiry = async () => {
  const token = getAuthToken()
  if (token) {
    // Decode the token and get its expiration time
    const decodedToken = jwtDecode(token);

    if (decodedToken && decodedToken.exp !== undefined) {
    const tokenExpiration = decodedToken.exp;
    const currentTime = getCurrentTimestampInSeconds();
    const timeUntilExpiration = calculateTimeDifferenceInSeconds(currentTime, tokenExpiration);


    if (timeUntilExpiration <= 20 && !isRefreshing) {
      isRefreshing = true;

      try {
        const newToken = await refreshAccessToken();
        isRefreshing = false;  
        localStorage.setItem(`token`, newToken)

      failedRequestsQueue.forEach((prom) => prom(newToken));
      failedRequestsQueue = [];
      } catch (refreshError) {
        isRefreshing = false;
        throw refreshError;
      }
    }
    }}
};
