import React from 'react';
import { useState } from 'react';
import { getAuthToken } from '../../../utils/token.utils';
import { Link } from 'react-router-dom';
import LogInModal from '../../../components/LogInModal';
import ConsentForm from '../consentForm/consentform';
import Robo from '../../../assets/images/chatscreen.png';

function App() {
  const [show, setShow] = useState(false);
  const [showConsent, setShowConsent] = useState(false);
  const [showConsentForm, setShowConsentForm] = useState(false);

  const handleClose = () => setShow(false);
  const handleCloseConsent = () => setShowConsent(false);
  const handleShow = () => setShow(true);

  const token = getAuthToken();
  const handleShowConsentForm = () => {
    setShowConsentForm(true);
    setShowConsent(true);
  };
  const handleConsentSubmit = () => {
    setShowConsentForm(false);
    setShow(true);
  };
  return (
    <>
      <section className='py-5 banner-section'>
        <div className='container'>
          <div className='row'>
            <div className='col-6'>
              <div className='bannerWrap mb-4'>
                <h1 className='mb-4'>
                  Easily Manage Your AWS Resources With{' '}
                  <strong>Garionix!</strong>{' '}
                </h1>
                <p className='mb-4'>
                  {' '}
                  Streamline your cloud infrastructure with easy-to-use
                  chat-based commands and intelligent automation
                </p>
                {token ? (
                  <Link to='/chat'>
                    <button className='univ-btn-light'>
                      Start Conversation
                    </button>
                  </Link>
                ) : (
                  <>
                    {' '}
                    <button
                      className='univ-btn-light'
                      onClick={handleShowConsentForm}
                    >
                      Get Started
                    </button>
                    {showConsentForm && (
                      <ConsentForm
                        show={showConsent}
                        handleCloseConsent={handleCloseConsent}
                        onSubmit={handleConsentSubmit}
                      />
                    )}
                    <LogInModal
                      show={show}
                      handleClose={handleClose}
                      handleShow={handleShow}
                    />
                  </>
                )}
              </div>
            </div>
            <div className='col-6'>
              {' '}
              <div className='worksImage'>
                <img
                  src={Robo}
                  style={{ width: '80%' }}
                  alt=''
                  className='im'
                />
              </div>{' '}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default App;
