import React from 'react';
const ChatTitle = () => {
  return (
    <div className='mt-3'>
      <div>
        {' '}
        <h3 className='chat-title-text'>Welcome to Garionix!</h3>
      </div>

      <p>
        Get started with new modern AI that helps you to create AWS resources
      </p>
    </div>
  );
};

export default ChatTitle;
