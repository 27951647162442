import React, { useState, useEffect, useRef } from 'react';
import Sidebar from '../../components/Sidebar';
import ChatTitle from './partials/ChatTitle';

import api from '../../utils/axiosInstance';

import { SystemChatIcon, UserChatIcon } from './partials/Chaticon';
import Errormessage from './partials/Errormessage';
import PromptInputBox from './partials/PromptInput';
import {
  handleResourceRelation,
  handlesinglestackskipbutton,
  handleSingleStackConfirmbutton,
} from './partials/singlestackfunction';

interface Conversation {
  sender: string;
  message: string;
  reply: string | JSX.Element;
  replier: string;
}

const Chat = () => {
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [messageInput, setMessageInput] = useState('');
  const [isNormalChatResponse, setIsNormalChatResponse] =
    useState<boolean>(true);
  const [singleStackdatakey, setSingleStackdataKeys] = useState<
    string[] | undefined
  >(undefined);

  const [loading, setLoading] = useState<boolean>(false);
  const [ResourceCreationstatus, setResourceCreationstatus] =
    useState<boolean>(false);
  const [IsSingleStackResourceCreation, setIsSingleStackResourceCreation] =
    useState<boolean>(false);
  const [IsMultiStackResourceCreation, setIsMultiStackResourceCreation] =
    useState<boolean>(false);
  const [singleStackRequiredProperties, setSingleStackRequiredProperties] =
    useState<string[]>([]);
  const [singleStackOptionalProperties, setSingleStackOptionalProperties] =
    useState<string[] | null>(null);
  const [gotsinglestackrequirerelation, setGotSingleStackkrequiiredRelation] =
    useState<boolean>(false);
  const [gotsinglestackoptionalalrelation, setGotSingleStackkoptionalRelation] =
    useState<boolean>(false);
  const [
    singleStackRequiredpropertieskey,
    setsingleStackRequiredpropertiesKey,
  ] = useState<string[]>([]);
  const [selectedOptionalProperties, setSelectedOptionalProperties] = useState<
    string[]
  >([]);
  const [StackStackGetStackName, setStackStackGetStackName] =
    useState<boolean>(false);
  const [gotregionforsinglestack, SetGotRegionForSingleStack] =
    useState<boolean>(false);
  const [SingleStackstackName, setSingleStackstackName] = useState<string>('');

  const firstSelectedValueRef = useRef<string | null>(null);

  const [error, setError] = useState('');
  const handleCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    prop: string
  ) => {
    const isChecked = e.target.checked;
    // Capture the first selected value when a checkbox is checked
    if (isChecked && !firstSelectedValueRef.current) {
      firstSelectedValueRef.current = prop;
    }

    setSelectedOptionalProperties((prevSelected) => {
      if (isChecked) {
        return [...prevSelected, prop];
      } else {
        return prevSelected.filter((item) => item !== prop);
      }
    });
  };

  const chatContainerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const chatContainer = chatContainerRef.current;
    if (chatContainer) {
      chatContainer.scrollTop = chatContainer.scrollHeight;
    }
  }, [conversations, loading]);

  const handleChat = async (input: string) => {
    setError('');
    setLoading(true);
    if (input.trim() === '') {
      return;
    }

    try {
      const res = await api.post(`api/users-input/initial-user-input`, {
        input: input.trim(),
      });

      if (res.data.input_type === 'resource_creation') {
        setIsNormalChatResponse(false);
        setResourceCreationstatus(true);
        if (res.data.response === 'single_stack') {
          setIsSingleStackResourceCreation(true);
          setIsMultiStackResourceCreation(false);

          setConversations([...conversations]);
          handleResourceRelation(
            messageInput,
            setSingleStackOptionalProperties,
            setGotSingleStackkrequiiredRelation,
            setSingleStackRequiredProperties,
            setConversations,
            setLoading,
            setMessageInput,
            conversations,
            setSingleStackdataKeys,
            setError
          );
        } else {
          setIsMultiStackResourceCreation(true);
          const newConversation: Conversation = {
            message: input,
            sender: 'You',
            reply: `Do you want any dependencies between these two parameters ?`,
            replier: 'Garionix',
          };

          setConversations([...conversations, newConversation]);
          setLoading(false);
          setMessageInput('');
        }
      } else if (res.data.input_type === 'resource_query') {
        setIsNormalChatResponse(true);
        const newConversation: Conversation = {
          message: input,
          sender: 'You',
          reply: `${res.data.response}`,
          replier: 'Garionix',
        };
        setMessageInput('');
        setConversations([...conversations, newConversation]);
        setLoading(false);
      } else if (res.data.input_type === 'general_chat') {
        setIsNormalChatResponse(true);
        const newConversation: Conversation = {
          message: input,
          sender: 'You',
          reply: `${res.data.response}`,
          replier: 'Garionix',
        };
        setMessageInput('');
        setConversations([...conversations, newConversation]);
        setLoading(false);
      } else {
        setLoading(false);
      }

      setError('');
    } catch (error) {
      console.error('Error:', error);
      setError('something  went wrong Please try again');
      setLoading(false);
    }
  };
  const handleSinglestackName = (input: any) => {
    setMessageInput('');
    const newConversation: Conversation = {
      sender: 'you',
      message: input,
      reply: `Please Provide Region`,
      replier: 'Garionix',
    };

    setSingleStackstackName(input);
    setConversations([...conversations, newConversation]);
    SetGotRegionForSingleStack(true);
    setStackStackGetStackName(false);
  };
  const handleGotSingleStackResion = async (input: any) => {
    console.log(SingleStackstackName);
    console.log(input);
    setLoading(true);
    const params = {
      stack_name: SingleStackstackName,
      region: input,
    };
    try {
      const res = await api.post(`api/auto-aws/stack-creation`, params);
      console.log(res.data.stack_status);
      console.log(res.data.dynamo_status);
      const templatereturn = (
        <div>
          {res.data.stack_status}
          <div>{res.data.dynamo_status}</div>
        </div>
      );
      setMessageInput('');
      const newConversation: Conversation = {
        sender: 'you',
        message: input,
        reply: templatereturn,
        replier: 'Garionix',
      };

      setConversations([...conversations, newConversation]);
      setLoading(false);
      setError('');
      setIsNormalChatResponse(true);
      setMessageInput('');
      setIsSingleStackResourceCreation(false);
      setGotSingleStackkrequiiredRelation(false);
      SetGotRegionForSingleStack(false);
    } catch (error: any) {
      setLoading(false);
      console.error('something went wrong.Please try again');
      setError(error); // Assuming setError is a function to set error state
      setIsNormalChatResponse(true);
      setMessageInput('');
      setIsSingleStackResourceCreation(false);
      setGotSingleStackkrequiiredRelation(false);
      SetGotRegionForSingleStack(false);
    }
  };

  const handleMultipleStackdependencies = async (input: any) => {
    setLoading(true);
    try {
      const res = await api.post(`api/multi-resource/resource-relation`, {
        resource_relation: input.trim(),
      });

      // Check if res.data is defined and is an object
      if (res.data && typeof res.data === 'object') {
        console.log('response', res.data);

        // Loop through the keys of res.data
        for (const key in res.data) {
          if (Object.prototype.hasOwnProperty.call(res.data, key)) {
            // Access the nested object for the current key
            const nestedObject = res.data[key];

            // Access properties of the nested object
            const requiredProperties = nestedObject.required_properties;
            const optionalProperties = nestedObject.optional_properties;

            // Log or process these properties as needed
            console.log(`Key: ${key}`);
            console.log('Required Properties:', requiredProperties);
            console.log('Optional Properties:', optionalProperties);

            // Example of using these properties within a template string
            const replyMessage = `Please Provide ${requiredProperties[0]} of ${key} `;

            // Create a new conversation with the reply message
            // const newConversation: Conversation = {
            //   sender: "You",
            //   message: input,
            //   reply: replyMessage,
            //   replier: "Garionix",
            // };

            // Add the newConversation to conversations
            // setConversations([...conversations, newConversation]);
          }
        }
      } else {
        console.error('Response data is undefined or not an object');
      }

      setLoading(false);
    } catch (error: any) {
      setError('something  went wrong Please try again');
      console.log(error);
      setLoading(false);
    }
  };

  const handleEnter = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      if (IsMultiStackResourceCreation) {
        handleMultipleStackdependencies(messageInput.trim());
      }
      if (gotregionforsinglestack) {
        handleGotSingleStackResion(messageInput.trim());
      }
      if (StackStackGetStackName) {
        handleSinglestackName(messageInput.trim());
      }
      if (gotsinglestackoptionalalrelation) {
        handlesinglestackoptionalproperties(messageInput.trim());
      }
      if (gotsinglestackrequirerelation) {
        handlesinglestackresources(messageInput.trim());
      } else if (isNormalChatResponse) {
        handleChat(messageInput.trim());
      }
    }
  };

  const handlesinglestackcancelbutton = (input: any) => {
    console.log(
      'singleStackRequiredpropertieskey',
      singleStackRequiredpropertieskey
    );
    const propertiesMessage = (
      <div>
        These are the values you have provided to create a resource.
        {Object.entries(singleStackRequiredpropertieskey).map(
          ([key, value], index) => (
            <div className='row' key={index}>
              <div className='col-6'>
                {' '}
                <h6 className='mt-3'>{key}:</h6>
              </div>
              <div className='col-6 mt-3'>{value}</div>
            </div>
          )
        )}
        {Object.entries(singlestackoptionalpropertieskeyvalue).map(
          ([key, value], index) => (
            <div className='row' key={index}>
              <div className='col-6'>
                {' '}
                <h6 className='mt-3'>{key}:</h6>
              </div>
              <div className='col-6 mt-3'>{value}</div>
            </div>
          )
        )}
      </div>
    );

    // Create a new conversation with the properties message
    const newConversation: Conversation = {
      sender: 'you',
      message: input,
      reply: propertiesMessage,
      replier: 'Garionix',
    };
    const cancelmessage: Conversation = {
      sender: '',
      message: '',
      reply: `Your Process  has been terminated`,
      replier: 'Garionix',
    };

    setConversations([...conversations, newConversation, cancelmessage]);
    setIsNormalChatResponse(true);
    setResourceCreationstatus(false);
    setIsSingleStackResourceCreation(false);
  };

  const [
    singlestackoptionalpropertieskeyvalue,
    setSingleStackOptionalPropertiesKeyValue,
  ] = useState<{ [key: string]: any }>({});

  const handlesinglestackoptionalproperties = (input: any) => {
    if (selectedOptionalProperties.length === 1) {
      console.log('selectedOptionalproperties', selectedOptionalProperties);
      console.log(input);

      setMessageInput('');
      // If there are no selected optional properties, show a message indicating the available properties
      const propertiesMessage = (
        <div>
          These are the values you have provided to create a resource. Are you
          sure you want to continue with these values?
          {Object.entries(singleStackRequiredpropertieskey).map(
            ([key, value], index) => (
              <div className='row' key={index}>
                <div className='col-6'>
                  <h6 className='mt-3'>{key}:</h6>
                </div>
                <div className='col-6 mt-3'>{value}</div>
              </div>
            )
          )}
          {Object.entries(singlestackoptionalpropertieskeyvalue).map(
            ([key, value], index) => (
              <div className='row' key={index}>
                <div className='col-6'>
                  <h6 className='mt-3'>{key}:</h6>
                </div>
                <div className='col-6 mt-3'>{value}</div>
              </div>
            )
          )}
          <div className='row'>
            <div className='col-6'>
              <h6 className='mt-3'>{selectedOptionalProperties.join(', ')}:</h6>
            </div>
            <div className='col-6 mt-3'>{input}</div>
          </div>
          <div className='text-end'>
            <button
              className='univ-btn me-2'
              onClick={() => handlesinglestackcancelbutton(input)}
            >
              Cancel
            </button>
            <button
              className='univ-btn'
              onClick={() =>
                handleSingleStackConfirmbutton(
                  singlestackoptionalpropertieskeyvalue,
                  singleStackRequiredpropertieskey,
                  singleStackOptionalProperties,
                  singleStackdatakey,
                  selectedOptionalProperties,
                  input,
                  setConversations,
                  conversations,
                  setLoading,
                  setStackStackGetStackName,
                  setGotSingleStackkrequiiredRelation,
                  setGotSingleStackkoptionalRelation,
                  setError,
                  setIsNormalChatResponse
                )
              }
            >
              Confirm
            </button>
          </div>
        </div>
      );

      // Create a new conversation with the properties message
      const newConversation: Conversation = {
        sender: 'you',
        message: input,
        reply: propertiesMessage,
        replier: 'Garionix',
      };

      // Update the conversations state with the new conversation
      setConversations([...conversations, newConversation]);
      return; // Exit the function early
    }

    // Proceed with the normal flow if there are selected optional properties
    const key = selectedOptionalProperties[0];

    // Update the state with the new key-value pair
    setSingleStackOptionalPropertiesKeyValue((prevState) => ({
      ...prevState,
      [key]: input,
    }));

    // Now you can use singlestackoptionalpropertieskeyvalue to access all the key-value pairs including the new one

    // Update selectedOptionalProperties by removing the first element
    setSelectedOptionalProperties(selectedOptionalProperties.slice(1));

    // Create the reply message
    const replymessage = (
      <div>
        Please Provide {selectedOptionalProperties[1]} for {singleStackdatakey}
      </div>
    );
    setMessageInput('');
    // Create a new conversation with the reply message
    const newConversation: Conversation = {
      sender: 'you',
      message: input,
      reply: replymessage,
      replier: 'Garionix',
    };

    // Update the conversations state with the new conversation
    setConversations([...conversations, newConversation]);
  };

  const handlesinglestackresources = async (input: any) => {
    setIsNormalChatResponse(false);
    const newKey = singleStackRequiredProperties[0];
    const newValues = {
      [newKey]: input,
    };
    setsingleStackRequiredpropertiesKey((prevState) => ({
      ...prevState,
      ...newValues,
    }));

    const newConversation: Conversation = {
      sender: 'you',
      message: input,
      reply: '',
      replier: 'Garionix',
    };
    setConversations([...conversations, newConversation]);

    if (gotsinglestackrequirerelation) {
      setMessageInput('');
      if (singleStackRequiredProperties.length > 1) {
        const nextRequiredProperty = singleStackRequiredProperties[1];

        newConversation.reply = `Please provide ${nextRequiredProperty} for ${singleStackdatakey}`;
        setConversations([...conversations, newConversation]);

        setSingleStackRequiredProperties((prevRequiredProperties) =>
          prevRequiredProperties.slice(1)
        );
      } else {
        // Check if there are optional properties and display the message
        if (
          singleStackOptionalProperties &&
          singleStackOptionalProperties.length > 0
        ) {
          const optionalPropsMessage = (
            <div>
              <div className='mb-2'>
                These are the optional properties for{' '}
                <strong>{singleStackdatakey}</strong> .You can skip the
                properties or provide values for the properties checked.
              </div>
              <div className='row'>
                <div className='col'>
                  {singleStackOptionalProperties
                    .slice(
                      0,
                      Math.ceil(singleStackOptionalProperties.length / 2)
                    )
                    .map((prop, index) => (
                      <div key={index} className='form-check'>
                        <input
                          type='checkbox'
                          id={`checkbox-${prop}-${index}`} // Unique ID for each checkbox
                          name={prop}
                          value={prop}
                          className='form-check-input'
                          onChange={(e) => handleCheckboxChange(e, prop)}
                        />
                        <label
                          htmlFor={`checkbox-${prop}-${index}`}
                          className='form-check-label'
                        >
                          {prop}
                        </label>
                      </div>
                    ))}
                </div>
                <div className='col'>
                  {singleStackOptionalProperties
                    .slice(Math.ceil(singleStackOptionalProperties.length / 2))
                    .map((prop, index) => (
                      <div key={index} className='form-check'>
                        <input
                          type='checkbox'
                          id={`checkbox-${prop}-${index}`} // Unique ID for each checkbox
                          name={prop}
                          value={prop}
                          className='form-check-input'
                          onChange={(e) => handleCheckboxChange(e, prop)}
                        />
                        <label
                          htmlFor={`checkbox-${prop}-${index}`}
                          className='form-check-label'
                        >
                          {prop}
                        </label>
                      </div>
                    ))}
                </div>
              </div>

              <div className='text-end'>
                <button
                  className='univ-btn me-2'
                  onClick={() =>
                    handlesinglestackskipbutton(
                      input,
                      singleStackRequiredProperties,
                      singleStackRequiredpropertieskey,
                      conversations,
                      setConversations,
                      singlestackoptionalpropertieskeyvalue,

                      singleStackOptionalProperties,
                      singleStackdatakey,
                      setLoading,
                      setStackStackGetStackName,
                      setGotSingleStackkrequiiredRelation,
                      setError,
                      setIsNormalChatResponse,
                      setResourceCreationstatus,
                      setIsSingleStackResourceCreation,
                      setGotSingleStackkoptionalRelation
                    )
                  }
                >
                  Skip
                </button>
                <button
                  className='univ-btn'
                  onClick={() => handleConfirm(input)}
                >
                  Confirm
                </button>
              </div>
            </div>
          );

          newConversation.reply = optionalPropsMessage;
          setConversations([...conversations, newConversation]);
        }

        setMessageInput('');
      }
    }
  };

  const handleConfirm = (test: any) => {
    const firstSelectedValue = firstSelectedValueRef.current;
    if (firstSelectedValue) {
      if (
        singleStackOptionalProperties &&
        singleStackOptionalProperties.length > 0
      ) {
        const optionalprops = (
          <div>
            {' '}
            <div>
              <div className='mb-2'>
                These are the optional properties for{' '}
                <strong>{singleStackdatakey}</strong>
              </div>
            </div>
            <div className='row'>
              <div className='col'>
                {singleStackOptionalProperties
                  .slice(0, Math.ceil(singleStackOptionalProperties.length / 2))
                  .map((prop, index) => (
                    <div key={index} className='form-check'>
                      <label
                        htmlFor={`checkbox-${prop}-${index}`}
                        className='form-check-label'
                      >
                        {prop}
                      </label>
                    </div>
                  ))}
              </div>
              <div className='col'>
                {singleStackOptionalProperties
                  .slice(Math.ceil(singleStackOptionalProperties.length / 2))
                  .map((prop, index) => (
                    <div key={index} className='form-check'>
                      <label
                        htmlFor={`checkbox-${prop}-${index}`}
                        className='form-check-label'
                      >
                        {prop}
                      </label>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        );
        const newConversation: Conversation = {
          message: '',
          sender: '',
          reply: `Please Provide ${firstSelectedValue} for ${singleStackdatakey}`,
          replier: 'Garionix',
        };
        const propconversation: Conversation = {
          message: test,
          sender: 'You',
          reply: optionalprops,
          replier: 'Garionix',
        };
        setMessageInput('');
        setConversations([...conversations, propconversation, newConversation]);
        setGotSingleStackkrequiiredRelation(false);
        setGotSingleStackkoptionalRelation(true);
      }
      // Perform further actions as needed
    } else {
      console.log('No option selected.');
    }
  };

  return (
    <>
      <div className='chatWrapper'>
        <div className='container-fluid'>
          <div className='main-container d-flex gx-5'>
            <Sidebar conversations={conversations} />
            <div
              className='content-1 ms-4 d-flex flex-column'
              ref={chatContainerRef}
            >
              {conversations.length === 0 && !loading ? (
                <ChatTitle />
              ) : (
                <h3 className='chat-title-text mb-5'>
                  Garionix
                </h3>
              )}
              <div className='top-content'>
                <>
                  {' '}
                  {conversations.map((conversation, index) => (
                    <div key={index}>
                      {conversation.message.trim() !== '' && (
                        <>
                          <UserChatIcon />
                          <div className='messages card-feature-question mb-3 mx-5 mt-1'>
                            {conversation.message}
                          </div>
                        </>
                      )}

                      {conversation.reply && (
                        <>
                          <SystemChatIcon />

                          <div className='reply card-feature-answer mb-3 mx-5 mt-1'>
                            {conversation.reply}
                          </div>
                        </>
                      )}
                    </div>
                  ))}
                  {error && <Errormessage />}
                  {loading && (
                    <div>
                      {messageInput.trim() !== '' && (
                        <>
                          <UserChatIcon />

                          <div className='messages card-feature-question mb-3 mx-5 mt-1'>
                            {messageInput}
                          </div>
                        </>
                      )}
                      <SystemChatIcon />

                      <div
                        className='spinner-grow spinner-grow-sm mx-5 mb-5'
                        role='status'
                      >
                        <span className='visually-hidden'>Loading...</span>
                      </div>
                    </div>
                  )}
                </>
              </div>
              <div className='bottom-content flex-grow-1 d-flex flex-column justify-content-end'>
                <div className='mt-auto'>
                  <PromptInputBox
                    messageInput={messageInput}
                    setMessageInput={setMessageInput}
                    handleEnter={handleEnter}
                    loading={loading}
                    handleChat={handleChat}
                    isNormalChatResponse={isNormalChatResponse}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chat;
